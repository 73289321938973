.App {
  text-align: center;
}

body {
  background-color: rgb(151, 151, 151);
  background: url('/public/img/background.png');
  color: red;
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: top center;
}